

export default function PageLoader() {
    return (<div className="loading">
        <section className="preloader is-absolute--fill">
            <div className="tp-preloader-ovr is-absolute--fill gjs-one-bg1"></div>
            <div className="tp-preloader-box">
                <div className="tp-preloader-title" style={{ color: 'hsla(0, 0%, 100%, 0.75)' }}>Loading Form Builder...<br /></div>
                <div className="spinner spinner--bottom" style={{ display: 'block' }}><i className="tp-spinner-large"></i></div>
            </div>
        </section>
    </div>)
}