import { CustomWidgetCollection, Serializer, type Question, SvgRegistry } from "survey-core";
import { PropertyGridEditorCollection } from "survey-creator-core";
declare var Datepicker: any;

SvgRegistry.registerIconFromSvg('icon-date', `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z"/></svg>`)

PropertyGridEditorCollection.register({
    fit: function (prop) {
        return prop.type === "dateprop";
    },
    getJSON: function () {
        return { type: "text", inputType: "date" };
    },
});

function formatDate(dateString: any, format: any) {
    if(!dateString) {
        return null;
    }
    const [year, month, day] = dateString.split("-");
    const formatMap: any = {
      'yyyy': year,
      'yy': year.slice(-2),
      'mm': month.padStart(2, '0'),
      'm': month,
      'dd': day.padStart(2, '0'),
      'd': day,
    };
  
    return format.replace(/yyyy|yy|mm|m|dd|d/g, (match: any) => formatMap[match]);
  }


CustomWidgetCollection.Instance.add({
    name: "datepicker",
    title: "Date",
    iconName: 'icon-date',
    isFit: (question: Question) => question.getType() === "datepicker",
    init: () => {
        Serializer.addClass("datepicker", [], undefined, "empty")

        Serializer.addProperty('datepicker', {
            name: "datePlaceholder:string",
            displayName: 'Date Placeholder',
            category: 'general',
            default: 'MM-DD-YYYY'
        });

        Serializer.addProperty('datepicker', {
            name: "dateFormat",
            type: 'string',
            displayName: 'Date Format',
            category: 'general',
            choices: ['MM-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD'],
            default: 'MM-DD-YYYY'
        });

        Serializer.addProperty('datepicker', {
            name: "startDate:dateprop",
            category: 'general'
        });
        Serializer.addProperty('datepicker', {
            name: "endDate:dateprop",
            category: 'general'
        });
    },
    htmlTemplate: `<div><input type="text" placeholder="MM-DD-YYYY" class="sd-input sd-text custom_widget_date" /></div>`,
    afterRender: (question: Question, el: any) => {
        const input = el.querySelector('input');

        if (question.isReadOnly) {
            input.setAttribute('readonly', true);
            input.setAttribute('disabled', true);
            input.classList.add('sd-input--disabled')
        }

        question.onPropertyChanged.add((sender, options) => {
            console.log(question.datePlaceholder, options.newValue);
            
            if (options.name === "datePlaceholder") {
                input.setAttribute('placeholder', options.newValue)
            }

            if (options.name == 'readOnly' || options.name == 'isReadOnly') {
                input.setAttribute('readonly', options.newValue);
                input.setAttribute('disabled', options.newValue);
                input.classList[options.newValue ? 'add' : 'remove']('sd-input--disabled')
            }

            if (options.name === 'dateFormat') {
                if (['MM-DD-YYYY', 'DD-MM-YYYY', 'YYYY-MM-DD'].includes(options.newValue)) {
                    input.setAttribute('placeholder', options.newValue)
                }
                question.datepicker.setOptions({ format: question.dateFormat.toLowerCase() });
            }
        });

        const initializeDatepicker = () => {
            if (input) {
                input.setAttribute('placeholder', question.datePlaceholder)
                question.datepicker = new Datepicker(input, {
                    format: question.dateFormat.toLowerCase(),
                    maxDate: formatDate(question.endDate, question.dateFormat.toLowerCase()),
                    minDate: formatDate(question.startDate, question.dateFormat.toLowerCase()),
                    enableOnReadonly: false
                });

                input.addEventListener('changeDate', function (event: any) {
                    question.value = event.detail.datepicker.getDate(question.dateFormat.toLowerCase());
                });
            }
        };

        if (typeof Datepicker === 'undefined') {
            // Load Datepicker CSS
            const cssLink = document.createElement('link');
            cssLink.href = 'https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.3.4/dist/css/datepicker.min.css'; // Provide the correct path
            cssLink.rel = 'stylesheet';
            cssLink.type = 'text/css';
            document.head.appendChild(cssLink);

            // Load Datepicker JS
            const script = document.createElement('script');
            script.src = 'https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.3.4/dist/js/datepicker-full.min.js'; // Provide the correct path
            script.onload = initializeDatepicker;
            document.body.appendChild(script);
        } else {
            initializeDatepicker();
        }
    },
    willUnmount: (question: Question, el: any) => {
        question.datepicker && question.datepicker.destroy();
    }
}, "customtype");
