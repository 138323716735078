import React from 'react';
import Select from 'react-select';
import FieldsMap from './FieldsMap';

const FormSection = ({
    section,
    sectionIndex,
    optimiserObjects,
    items,
    handleObjectChange,
    handleInputChange,
    handleAddField,
    handleDeleteField,
    isFormDisabled,
    getPreviousSectionIds,
    handleObjectDelete
}: any) => (
    <div className="bg-light p-4 rounded mt-4">
        <div className={"section-delete" + " " + (isFormDisabled && "is-disabled")}><span onClick={() => handleObjectDelete(sectionIndex)} className='fa fa-trash-alt trash-icon'></span></div>
        <div className="form-group">
            <label>Object</label>
            <Select
                isClearable={true}
                isDisabled={isFormDisabled}
                options={optimiserObjects}
                value={section.ObjectName ? { value: section.ObjectName, label: optimiserObjects.find((o:any)=>o.Name == section.ObjectName).DisplayName } : ""}
                onChange={(e: any) => handleObjectChange(sectionIndex, e ? e.value :"")}
            />
            {section.validationMessages.ObjectName && (
                <div className="error">{section.validationMessages.ObjectName}</div>
            )}
        </div>
        {section.FieldsMap.map((field: any, fieldIndex: number) => (
            <FieldsMap
                key={fieldIndex}
                field={field}
                items={items}
                sectionIndex={sectionIndex}
                fieldIndex={fieldIndex}
                section={section}
                handleInputChange={handleInputChange}
                handleDeleteField={handleDeleteField}
                isFormDisabled = {isFormDisabled}
                getPreviousSectionIds ={getPreviousSectionIds}
            />
        ))}
        <div className={"text-right pt-3 font-weight-500 add-field-bx " + " "+ (isFormDisabled && 'is-disabled') }>
            <a href="" onClick={(e) => handleAddField(e, sectionIndex)}>+ add field</a>
        </div>
        <div className="clear"></div>
    </div>
);

export default FormSection;
