import { useEffect, useState } from "react";
import GeneralSettings from "./General";
import EmailSettings from "./email/Email";
import OptmiserIntegration from "./integration/optimiser/Index";
//import { Action } from 'survey-core'
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import Record from "./form-record/Record";

const SettingsComponent = ({ creator }: any) => {
	const [activeEmail, setActiveEmail] = useState("user");
	const [activeSetting, setActiveSetting] = useState("general");
	const isCheck = useSelector((state: RootState) => state.project.isCheck);
	const projectConfig = useSelector((state: RootState) => state.project.projectConfig);
	
	const menuClass = (type: string): string => (activeSetting == type ? "menu-item active" : "menu-item");

	useEffect(() => {
		updateStatus();
		return () => {
			const saveBtn = creator.toolbarItems.find((item: any) => item.id == "save-status");
			saveBtn.visible = false;
		};
	}, [isCheck]);

	const updateStatus = () => {
		const saveBtn = creator.toolbarItems.find((item: any) => item.id == "save-status");
		saveBtn.visible = true;
		saveBtn.iconName = isCheck ? "icon-moderncheck" : "icon-more";
		saveBtn.css = isCheck ? "btn-outline-secondary active" : "btn-outline-secondary";
	};

	const renderTab = () => {
		let content;
		switch (activeSetting) {
			case "general":
				content = <GeneralSettings creator={creator} />;
				break;
			case "email":
				content = <EmailSettings activeEmail={activeEmail} creator={creator} />;
				break;
			case "optmiserintegration":
				content = <OptmiserIntegration creator={creator} />;
				break;
			case 'record':
				content = (<Record  />)
				break;
		}
		return content;
	};

	return (
		<>
			<div className="svc-flex-column">
				<div className="svc-toolbox">
					<div className={menuClass("general")} onClick={() => setActiveSetting("general")}>
						<span>General</span>
					</div>
					<div className={menuClass("email")} onClick={() => setActiveSetting("email")}>
						<span>Email Settings</span>
					</div>
				{ projectConfig.OptimiserSyncEnabled && (	<div className={menuClass("optmiserintegration")} onClick={() => setActiveSetting("optmiserintegration")}>
						<span>Optimiser CRM Integration</span>
					</div> )}
					<div className={menuClass('record')} onClick={() => setActiveSetting('record')}>
                        <span>Record</span>
                    </div>
				</div> 
			</div>
			{activeSetting == "email" && (
				<div className="setting-gray-bx">
					<div className="setting-link">
						<a href="#" className={`${activeEmail == "user" ? "active" : ""}`} onClick={() => setActiveEmail("user")}>
							User Email
						</a>
						<a href="#" className={`${activeEmail == "admin" ? "active" : ""}`} onClick={() => setActiveEmail("admin")}>
							Admin Email
						</a>
					</div>
				</div>
			)}
			<div className="svc-tab-designer">
				<div className="tab-content">{renderTab()}</div>
			</div>
		</>
	);
};

export default SettingsComponent;
