import React from 'react';
import Select from 'react-select';
import FieldsMap from './FieldsMap';
import { Tooltip } from 'react-tooltip';
const FormSection = ({
    section,
    sectionIndex,
    optimiserObjects,
    items,
    handleObjectChange,
    handleInputChange,
    handleDeleteField,
    handleUniqueObjectChange,
    handleUniqueFieldsChange,
    isFormDisabled,
    handleRadioChange,
    handleAddField,
    getPreviousSectionIds,
    handleObjectDelete,
    handleSliderChange
}: any) => (
    <div className="bg-light p-4 rounded mt-4">
        {/* <div className={"fa fa-trash-alt trash-icon section-delete" + " " + (isFormDisabled && "is-disabled")} onClick={() => handleObjectDelete(sectionIndex)}></div> */}
        <div className="form-group">
            <label>Object</label>
            <Select
                isClearable={true}
                isDisabled={section.IsNew == true ? false : true}
                options={optimiserObjects}
                value={section.ObjectName ? { value: section.ObjectName, label: optimiserObjects.find((o: any) => o.Name == section.ObjectName).DisplayName } : ""}
                onChange={(e: any) => handleObjectChange(sectionIndex, e ? e.value : "")}
            />
            {section.validationMessages.ObjectName && (
                <div className="error">{section.validationMessages.ObjectName}</div>
            )}
            <div className="section-for-update">
                <p className="mb-0 mt-0">Select a field basis which we can identify a unique record</p>
                <div className="form-sec object-sec">
                    <div className="field-bx form-group mb-0">
                        <label>Form Field</label>
                        <Select
                            isClearable={true}
                            isDisabled={section.UniqueFields[0].IsNew == true ? false : isFormDisabled}
                            options={items.concat(getPreviousSectionIds(sectionIndex))}
                            value={section.UniqueFields[0].FormFieldName ? { value: section.UniqueFields[0].FormFieldName, label: items.find((f: any) => f.name == section.UniqueFields[0].FormFieldName) ? items.find((f: any) => f.name == section.UniqueFields[0].FormFieldName).label : section.UniqueFields[0].FormFieldName } : ""}
                            onChange={(e: any) => handleUniqueFieldsChange(sectionIndex, "FormFieldName", e ? e.value : "")}
                        />
                        {section.validationMessages.UniqueFields[0].FormFieldName && (
                            <div className="error">{section.validationMessages.UniqueFields[0].FormFieldName}</div>
                        )}
                    </div>
                    <div className="Optimiser-bx form-group mb-0">
                        <label>Optimiser Field</label>
                        <Select
                            isClearable={true}
                            isDisabled={section.UniqueFields[0].IsNew == true ? false : isFormDisabled}
                            options={section.ObjectFields}
                            value={section.UniqueFields[0].OptimiserFieldName ? { value: section.UniqueFields[0].OptimiserFieldName, label: section.ObjectFields.find((f: any) => f.value == section.UniqueFields[0].OptimiserFieldName)?.label } : ""}
                            onChange={(e: any) => handleUniqueFieldsChange(sectionIndex, "OptimiserFieldName", e ? e.value : "")}
                        />
                        {section.validationMessages.UniqueFields[0].OptimiserFieldName && (
                            <div className="error">{section.validationMessages.UniqueFields[0].OptimiserFieldName}</div>
                        )}
                    </div>
                    <div className="icon-bx text-left" style={{ marginTop: "32px" }}></div>
                    <div className="clear"></div>
                </div>

            </div>

        </div>
        <div className={"d-flex mb-15" + " " + (isFormDisabled && "is-disabled")}>
            <div className="form-check form-check-inline">
                <input
                    className="form-check-input d-inline-block mr-8"
                    checked={section.SelectedOption == "option1"}
                    type="radio"
                    name={"inlineRadioOptions1" + sectionIndex}
                    id={"inlineRadio1" + sectionIndex}
                    value="option1"
                    onChange={(e) => handleRadioChange(sectionIndex, e.target.value)}
                />
                <label className="form-check-label d-inline-block" htmlFor={"inlineRadio1" + sectionIndex}>I will choose the fields which needs to be updated</label>
            </div>
            <div className="form-check form-check-inline d-inline-block" style={{ "marginLeft": "30px" }}>
                <input
                    className="form-check-input d-inline-block mr-8"
                    type="radio"
                    checked={section.SelectedOption == "option2"}
                    name={"inlineRadioOptions2" + sectionIndex}
                    id={"inlineRadio2" + sectionIndex}
                    value="option2"
                    onChange={(e) => handleRadioChange(sectionIndex, e.target.value)}
                />
                <label className="form-check-label d-inline-block" htmlFor={"inlineRadio2" + sectionIndex}>All form fields needs to be updated</label>
                <span>
                    <a style={{ color: '#075da8', marginLeft: '4px' }} id={"fieldUpdateInfo" + sectionIndex}><i className="fa fa-info-circle"></i></a>
                    <Tooltip
                        anchorSelect={"#fieldUpdateInfo" + sectionIndex}
                        variant='light'
                        border={'1px solid #075da8'}
                        content="All fields irrespective of the value available or not in Optimiser, will be added or replaced"
                        place='right'
                    />
                </span>
            </div>
        </div>



        {section.SelectedOption == "option1" && section.FieldsMap.map((field: any, fieldIndex: number) => (
            <FieldsMap
                key={fieldIndex}
                field={field}
                items={items}
                sectionIndex={sectionIndex}
                fieldIndex={fieldIndex}
                section={section}
                handleInputChange={handleInputChange}
                handleDeleteField={handleDeleteField}
                getPreviousSectionIds={getPreviousSectionIds}
                isFormDisabled={isFormDisabled}
                handleSliderChange={handleSliderChange}
            />
        ))
        }
        {/* {
            section.SelectedOption == "option1" &&
            (<div className={"text-right pt-3 font-weight-500 add-field-bx" + " " + (isFormDisabled ? "is-disabled" : "")}>
                <a href="" onClick={(e) => handleAddField(e, sectionIndex)}>+ add field</a>
            </div>)
        } */}


        <div className="clear"></div>
    </div>
);

export default FormSection;
