import moment from "moment-timezone";
import { useState } from "react";

const Header = ({
  headerText,
  closeDetails,
  formId,
  setShowEditRecord,
  setShowViewRecord,
  showEditRecord,
  saveEditRecord,
  downloadPdf,
  deleteRecords
}: any) => {
  const onEdit = () => {
    setShowEditRecord(true);
    setShowViewRecord(false);
  };
  return (
    <div className="row border-bottom pb-3">
      <div className="col-6">
        <div className="pt-3">
          <h2 className="font-weight-bold text-primary p-0 m-0">
            {headerText.formTitle}
          </h2>
          <h5 className="font-weight-bold">{headerText.fullName}</h5>
          <div className="fontSize">
            Submission :{" "}
            {moment(headerText.submission_date).format("DD-MMM-YYYY h:mm A")}
          </div>
          <div className="fontSize">Form ID : {formId}</div>
          <div className="fontSize">IP ID : {headerText.IPAddress}</div>
          <div className="fontSize" v-if="showViewRecord">
            Last edited on {headerText.updatedDate} by {headerText.updatedBy}
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="pr-3">
          <div className="text-lg-right">
            {!showEditRecord ?  (<button
              className="btn btn-sm btn-primary ml-2"
              title="Edit Record"
              onClick={() => onEdit()}
            >
              <i className="fa fa-edit" aria-hidden="true"></i>
            </button>) : (<button
              className="btn btn-sm btn-primary ml-2"
              title="Edit Record"
              onClick={() => saveEditRecord()}
            >
              Save
            </button> )}
            <button
              className="btn btn-sm btn-secondary ml-2"
              title="Download Pdf"
              onClick={()=> downloadPdf()}
            >
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
            <button
              className="btn btn-sm btn-secondary ml-2"
              title="Delete Record"
              onClick={()=>deleteRecords()}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
            <button
              className="btn btn-sm btn-secondary rounded-circle ml-2"
              title="Close"
              onClick={() => closeDetails()}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
