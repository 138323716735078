import { useState, useRef, useEffect } from 'react';
import useEmailTab from './EmailTab';
import SubjectInput from './SubjectEditor';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setCheck, updateEmailSettings } from '../../../store';
import useAxios from '../../../hooks/useAxios';
import { Success } from '../../../config/swal';
import TemplateEditor from './TemplateEditor';
import PreviewEditor from './PreviewEditor';
import { Tooltip } from 'react-tooltip';


interface AutoResponder {
	Enabled: boolean;
	RecipientEmail: string;
	SenderEmail: string;
	SenderName: string;
	Content: string;
	Subject: string;
}

const UserEmail = ({ creator }: any) => {
	const formData = useSelector((state: RootState) => state.project.formData);
	const verifiedEmails = useSelector((state: RootState) => state.project.verifiedEmails);
	const userEmail = useSelector((state: RootState) => state.project.emailSettings.AutoResponder);
	const [emailFields, setEmailFields] = useState([]);
	const dispatch = useDispatch();
	const axios = useAxios();
	const [showEditor, setShowEditor] = useState(false);
	const [showPreview, setShowPreview] = useState(false);
	const submitBtn: any = useRef(null);

	const { register, handleSubmit, formState: { errors }, setValue, getValues } = useForm<AutoResponder>({
		values: userEmail
	});

	const { activeTab, EmailTab } = useEmailTab();

	const framRef: any = useRef(null)

	const onFieldBlur = (field: any) => {
		if (getValues(field) !== userEmail[field]) {
			submitBtn.current.click();
		}
	}
	const rules = {
		Enabled: register('Enabled', {
			onChange: () => {
				onFieldBlur('Enabled');
				dispatch(setCheck(false));
			}
		}),
		RecipientEmail: register('RecipientEmail', {
			required: {
				value: true, message: 'Recipient email is required'
			},
			onChange: (e) => {
				onFieldBlur('RecipientEmail');
				dispatch(setCheck(false));
			}
		}),
		SenderEmail: register('SenderEmail', {
			required: 'Sender email is required', onChange(e) {
				onSenderChange(e)
				onFieldBlur('SenderEmail')
				dispatch(setCheck(false));
			}
		}),
		SenderName: register('SenderName', {
			required: 'Sender name is required',
			onBlur: () => onFieldBlur('SenderName'),
			onChange: () => {
				dispatch(setCheck(false));
			}
		}),
		Subject: register('Subject', { required: 'Subject is required' }),
		Content: register('Content', {
			onChange: () => {
				dispatch(setCheck(false));
			}
		}),
	}

	function onPreviewLoad() {
		setTimeout(() => {
			const iframe: any = document.getElementById('myFrame');
			const body = iframe.contentDocument.body;
			const html = iframe.contentDocument.documentElement;
			const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
			iframe.style.height = height + 'px';
		}, 0);
	}

	const onSenderChange = (e: any) => {
		const { SenderName } = verifiedEmails.find((ve: any) => ve._id == e.target.value);
		setValue('SenderName', SenderName)
	}

	useEffect(() => {
		if (formData) {
			const elements = formData.Metadata.Content.pages?.map((p: any) => p.elements || []).flat(1) || [];
			setEmailFields(elements.filter((el: any) => el.inputType == 'email' || el.type == 'emailfield'));
		}
	}, [formData])

	// Handles the message event correctly and removes it
	useEffect(() => {
		const messageHandler = (event: any) => {
			if (event.data.type == 'EmailContent') {
				if (userEmail.Content != event.data.content) {
					setValue('Content', event.data.content)
					SaveContent(event.data.content)
				}
			}
			if (event.data.type == 'CloseEmailContent') {
				setShowEditor(false)
			}
		}

		window.addEventListener('message', messageHandler);

		return () => {
			window.removeEventListener("message", messageHandler);
		}
	}, [userEmail, window])

	const SaveContent = async (EmailContentString: string) => {
		const value = { ...userEmail };
		value['Content'] = EmailContentString
		await updateEmailSettings({ _id: formData._id, patchData: { target: 'AutoResponder', value }, axios })(dispatch);
		// Success({ title: 'User email Content updated' })
	}

	const items = formData.Metadata.Content.pages?.map((p: any) => p.elements || []).flat(1) || [];
	// const items2 = formData.Metadata.Content.pages.map((p: any) => p.elements.map((el: any) => { return { "value": el.name, "key": el.valueName } })).flat(1);
	const pages = formData.Metadata.Content.pages || [];

	const onSubmit = async (data: any) => {
		try {
			const value = { ...userEmail, ...data };
			value['Enabled'] = getValues('Enabled')
			await updateEmailSettings({ _id: formData._id, patchData: { target: 'AutoResponder', value }, axios })(dispatch);
			dispatch(setCheck(true));
			// Success({ title: 'User email updated' })
		} catch (error) {
			console.log(error);
		}
	}

	const onSubjectChange = (value: string) => {
		setValue('Subject', value);
		dispatch(setCheck(false));
	}

	return (
		<div >
			<div className="form-cb-group mb-2">
				<label style={{ paddingRight: '10px' }}>Enable User Email </label>
				<label className="switch">
					<input {...rules.Enabled} type="checkbox" />
					<span className="slider round"></span>
				</label>
				<a style={{ color: '#075da8', marginLeft: '8px' }} id="userEmailInfo"><i className="fa fa-info-circle"></i></a>
				<Tooltip
					anchorSelect="#userEmailInfo"
					variant='light'
					border={'1px solid #075da8'}
					content="By turning this on you can set up the email received by the user when the form is submitted"
					place='right'
				/>
			</div>
			<EmailTab />

			<div className='tab-content2'>
				{activeTab == 'setting' ? (
					<form className='settings-form' id='userForm' onSubmit={handleSubmit(onSubmit)}>

						<div className="form-group">
							<label>Recipient Email <span className='required'>*</span></label>
							<select id="RecipientEmail" {...rules.RecipientEmail} className="form-control">
								<option disabled value={''}>Select an email field</option>
								{emailFields.map((ef: any, index: number) => <option key={index + '_ef'} value={ef.valueName}>{ef.name}</option>)}
							</select>
							{errors.RecipientEmail && <span className='error'>{errors.RecipientEmail.message}</span>}
						</div>

						<div className="form-group">
							<label>Sender Email <span className='required'>*</span></label>
							<select id="senderEmail" {...rules.SenderEmail} className="form-control">
								<option disabled value={''}>Select a sender email</option>
								{verifiedEmails.map((ve: any, index: number) => <option key={index} value={ve._id}>{ve.SenderName} {'<' + ve.SenderEmail + '>'}</option>)}
							</select>
							{errors.SenderEmail && <span className='error'>{errors.SenderEmail.message}</span>}
						</div>

						<div className="form-group">
							<label>Sender Name <span className='required'>*</span></label>
							<input {...rules.SenderName} placeholder="Enter sender name" type="text" id="senderName" className="form-control" />
							{errors.SenderName && <span className='error'>{errors.SenderName.message}</span>}
						</div>
					</form>) : (
					<form id='userForm' className='settings-form lg w-100' onSubmit={handleSubmit(onSubmit)}>
						<SubjectInput errors={errors} defaultValue={userEmail.Subject} items={items} onChange={(value: string) => onSubjectChange(value)} onBlur={() => onFieldBlur('Subject')} />

						<div className="form-group d-flex justify-content-between">
							<label>Email Body</label>
							<div className="actn-btns">
								<button type='button' className='btn outline' onClick={() => setShowPreview(true)}>Preview</button>
								<button type='button' className='btn' onClick={() => setShowEditor(true)}>Edit</button>
							</div>
						</div>

						<iframe id='myFrame' ref={framRef} onLoad={onPreviewLoad} srcDoc={userEmail.Content}
							style={{ width: '100%', height: '100%', border: 'none', userSelect: 'none', pointerEvents: 'none' }}></iframe>
					</form>
				)}
			</div>
			{showEditor ? <TemplateEditor setShowEditor={setShowEditor} items2={pages} content={userEmail.Content} TemplateType='User'></TemplateEditor> : ''}
			{showPreview ? <PreviewEditor setShowPreview={setShowPreview} items2={pages} content={userEmail.Content}></PreviewEditor> : ''}

			<div style={{ display: 'none' }}>
				<button form='userForm' ref={submitBtn} type="submit" className="btn">Save</button>
			</div>
		</div>
	)
}

export default UserEmail;