function generateRandomAlphanumeric(length: number): string {
    let result: string = '';
    const characters: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength: number = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function generateUniqueValueName(): string {
    const timestamp: string = new Date().getTime().toString().slice(-4);
    const randomAlphanumeric: string = generateRandomAlphanumeric(3);
    return `TP_${timestamp}_${randomAlphanumeric}`;
}

export function debounce(func: any, wait: any) {
    let timeout: any;
    
    return function(...args: any) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            func(...args);
        }, wait);
    };
}