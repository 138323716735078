import { useEffect, useState } from 'react';
import SurveyCreatorWidget from './components/SurveyCreator';
import useAxios from './hooks/useAxios';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormMetadata, updateProjectConfig, loadVerifiedEmails, RootState } from './store';
import { ConfirmWithYesAndNo, WarningWithOk } from './config/swal';
import PageLoader from './components/utils/Loader';
import Axios from 'axios'
import socket from './config/socket';

function App() {
	const axios = useAxios();
	const dispatch = useDispatch();

	const [isDataFetched, setIsDataFetched] = useState(false);
	const [initData, setInitData] = useState<Record<any, any>>({})
	const [activeTab, setActiveTab] = useState('designer')
	const config = useSelector((state: RootState) => state.project.projectConfig);

	const FetchProjectConfig = async () => {
		try {
			const { data } = await axios.get(`common/project-config`);
			dispatch(updateProjectConfig(data));
		} catch (error) {
			console.log(error);
		}
	}

	const FetchFormData = async (tab?: string) => {
		try {
			if (tab) setActiveTab(tab);
			const params = new URLSearchParams(window.location.search);
			let formId = params.get('formId');
			const { data } = await axios.get(`forms/${formId}`);
			dispatch(updateFormMetadata(data));
			setInitData(data)
			setIsDataFetched(true)
		} catch (error) {
			console.log(error);
		}
	}

	const TrackUnsavedChangesInFrameState = async (event: any) => {
		if (event.data.type == 'checkUnsavedChanges') {
			if (!canExit()) {
				const { isConfirmed } = await ConfirmWithYesAndNo({
					html: "<span style='font-size:15px; color:#686868;'>Changes made have not been saved. Are you sure you want to exit the Form editor?</span>"
				})
				window.parent.postMessage({ type: 'unsavedChangesResponse', value: isConfirmed }, '*');
			} else {
				window.parent.postMessage({ type: 'unsavedChangesResponse', value: true }, '*');
			}
		}
	}

	const canExit = () => {
		let designer: any = document.querySelector('[title="Save Form"]');
		let theme: any = document.querySelector('[title="Save Theme"]');
		return (designer && designer.disabled) && (theme && theme.disabled)
	}

	const verifyOneTimeToken = async (token: string) => {
		try {
			const { data } = await Axios.post('/api/no-auth/editor-login', { oneTimeToken: token });
			window.location.href = '/' + data.uri;
		} catch (error) {
			console.log(error);
		}
	}


	useEffect(() => {
		const cbtoken = (new URLSearchParams(window.location.search)).get('cbtoken');

		const HandleBeforeUnload = (e: any) => {
			if (!canExit()) {
				e.preventDefault();
				e.returnValue = "You have unsaved changes. Are you sure you want to leave?";
			}
		};
		if (!cbtoken) {
			socket.connect();
			socket.emit("JoinRoom", { id: config._id })
			onOpenFormbuilder();
			FetchProjectConfig().then(() => {
				FetchFormData().then(() => loadVerifiedEmails({ axios })(dispatch))
			});
			window.addEventListener('message', TrackUnsavedChangesInFrameState);
			window.addEventListener('beforeunload', HandleBeforeUnload);
		} else {
			verifyOneTimeToken(cbtoken)
		}



		return () => {
			if (socket.connected) {
				socket.emit('LeaveRoom', { id: config._id });
				socket.disconnect();
			}
			window.removeEventListener('beforeunload', HandleBeforeUnload);
			window.removeEventListener('message', TrackUnsavedChangesInFrameState);
		};
	}, [])

	const onOpenFormbuilder = () => {
		const loc: any = document.location;
		let params = new URL(loc).searchParams;
		let FormID = params.get('formId');
		let pid = params.get('wbid');
		socket.emit('FormAccessed', { pid, FormID });
		socket.on('FormBusy', () => { });
		socket.on('RestrictForm', (data: any) => { });
		socket.on('RemoveFormRestrictionByAdmin', async (f: any) => {
			if (f.form == FormID) {
				const { isConfirmed } = await WarningWithOk({
					html: `<span style='font-size:15px; color:#686868;'>You have no longer have access to this Form. Please contact the administrator</span>`,
					reverseButtons: true,
					confirmButtonText: `OK`,
					customClass: {
						confirmButton: 'btn btn-primary btn-sm',
					},
					allowOutsideClick: () => false,
				});
				if (isConfirmed) {
					let wind: any = window;
					wind.top.close();
				}
			}
		});
	}

	return isDataFetched ? <SurveyCreatorWidget tab={activeTab} FetchFormData={FetchFormData} initData={initData.Metadata} _id={initData._id} /> : <PageLoader></PageLoader>;
}

export default App;
