// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProjectState {
    // _id: string | null,
    projectConfig: Record<string, any>;
    formData: Record<string, any>;
    // metadata: Record<string, any>;
    emailSettings: Record<string, any>;
    verifiedEmails: Record<string, any>;
    integration:Record<string,any>;
    isCheck: boolean;
}

const initialState: ProjectState = {
    // _id: null,
    projectConfig: {},
    formData: {},
    // metadata: {},
    emailSettings: {},
    verifiedEmails: [],
    integration:{},
    isCheck: true
};


const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        updateProjectConfig(state, action: PayloadAction<Record<string, any>>) {
            state.projectConfig = { ...state.projectConfig, ...action.payload };
        },
        updateFormMetadata(state, action: PayloadAction<Record<string, any>>) {
            state.formData = { ...state.formData, ...action.payload };
            // state._id = state.formData._id;
            state.emailSettings = state.formData.Settings.Email;
            // state.metadata = state.formData.Metadata;
        },
        patchMetadata(state, action: PayloadAction<Record<string, any>>) {
            state.formData.Metadata.Content = action.payload;
        },
        updateVerifiedEmails(state, action: PayloadAction<Record<string, any>>) {
            state.verifiedEmails = action.payload;
        },
        setEmailSettings(state, action: PayloadAction<Record<string, any>>) {
            state.emailSettings[action.payload.target] = action.payload.value;
        },
        setFormData(state, action: PayloadAction<Record<string, any>>) {
            Object.keys(action.payload).forEach((k: string) => state.formData[k] = action.payload[k])
        },
        setCheck(state, action) {
            state.isCheck = action.payload;
        },
        updateIntegration(state, action) {
            state.integration = {...state.integration,...action.payload};
        }
    }
});

export const {
    updateProjectConfig,
    updateVerifiedEmails,
    updateFormMetadata,
    // patchMetadataTheme,
    patchMetadata,
    setEmailSettings,
    setFormData,
    setCheck,
    updateIntegration
} = projectSlice.actions;

// Async thunk action
export const updateMetadata = (payload: any) => async (dispatch: any) => {
    try {
        const { data } = await payload.axios.patch(`forms/metadata/${payload._id}`, payload.patchData);
        dispatch(patchMetadata(payload.patchData));
        if (data.Email) {
            dispatch(setEmailSettings({ target: 'AutoResponder', value: data.Email.AutoResponder }))
            dispatch(setEmailSettings({ target: 'Notification', value: data.Email.Notification }))
        }
        payload.callback && payload.callback();
    } catch (error) {
        throw error;
    }
};

// Async thunk action
export const updateMetadataTheme = (payload: any) => async (dispatch: any) => {
    try {
        await payload.axios.patch(`forms/metadata/theme/${payload._id}`, payload.patchData);
        // dispatch(patchMetadataTheme(payload.patchData));
        payload.callback && payload.callback();
    } catch (error) {
        throw error;
    }
};

export const loadVerifiedEmails = (payload: any) => async (dispatch: any) => {
    try {
        const { data } = await payload.axios.get(`settings/verified-senders`);
        dispatch(updateVerifiedEmails(data));
    } catch (error) {
        throw error;
    }
}

export const updateEmailSettings = (payload: any) => async (dispatch: any) => {
    try {
        await payload.axios.patch(`forms/email-setting/${payload._id}`, payload.patchData);
        dispatch(setEmailSettings(payload.patchData));
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const updateFormSettings = (payload: any) => async (dispatch: any) => {
    try {
        const { message } = await payload.axios.put(`forms/form-setting`, payload.patchData);
        dispatch(setFormData(payload.patchData));
        return message
    } catch (error) {
        throw error;
    }
}


const store = configureStore({
    reducer: {
        project: projectSlice.reducer
    }
});

// Infer the RootState and AppDispatch types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
