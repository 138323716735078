import { useState, useRef } from 'react';

export default function TemplateEditor({ setShowPreview, content }: any) {
    const frameRef = useRef(null);
    const [iframeWidth, setIframeWidth] = useState('100%');
    const [initHeight, setInitHeight] = useState(0);

    content =  `
    <head>
    <style type="text/css">
        @media only screen and (max-width: 480px) {
            [width="undefined"] {width:100% !important}
            body{width:100% !important;vertical-align:top;}
            body > center{width:100% !important}
            .template-mail-body{width:100% !important}
            td:not(.social-media-icon){display:block !important;width:100% !important;box-sizing: border-box;}
            .block-col{display:block !important;width:100% !important;box-sizing: border-box;}
            .youtube-block,.img{height:auto !important;width:100% !important;box-sizing: border-box !important}
        }
        td:empty {height: 50px;}
    </style></head>` + content
        
    function onPreviewLoad() {
        const iframe: any = frameRef.current;
        if (iframe) {
            const body = iframe.contentDocument.body;
            const html = iframe.contentDocument.documentElement;
            const height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            iframe.style.height = height + 'px';
            if (!initHeight) setInitHeight(height);
        }
    }
    function ChangeSize(size: any, type: string) {
        setIframeWidth(size)
        setTimeout(() => {
            if (type == 'mobile') {
                onPreviewLoad()
            } else {
                const iframe: any = frameRef.current;
                if (iframe) iframe.style.height = initHeight + 'px';
            }
        }, 0);
    }
    // content += `
    // <html>
    // <head>
    //     <style>
    //         @media only screen and (max-width: 480px) {
    //             [width="undefined"] {width:100% !important}
    //             .template-page-body{width:100% !important;vertical-align:top;}
    //             .template-page-body > center{width:100% !important}
    //             .template-mail-body{width:100% !important}
    //             .td-col{display:block !important;width:100% !important;box-sizing: border-box;}
    //             .block-col{display:block !important;width:100% !important;box-sizing: border-box;}
    //             .youtube-block,.img{height:auto !important;width:100% !important;box-sizing: border-box !important}
    //         }
    //     </style>
    // </head>
    // ${content}
    // </html>
    // `
    return (
        <div className="preview-screen">
            <div className="topbar">
                <div>
                    <span className={iframeWidth == '100%' ? 'devices active' : 'devices'} onClick={() => ChangeSize('100%', 'desktop')}>
                        <i className="fa fa-desktop"></i>
                    </span>
                    <span className={iframeWidth == '700px' ? 'devices active' : 'devices'} onClick={() => ChangeSize('700px', 'desktop')}>
                        <i className="fa fa-tablet"></i>
                    </span>
                    <span className={iframeWidth == '320px' ? 'devices active' : 'devices'} onClick={() => ChangeSize('320px', 'mobile')}>
                        <i className="fa fa-mobile"></i>
                    </span>
                </div>
                <div className='close' onClick={() => setShowPreview(false)}>
                    <i className="fa fa-times"></i>
                </div>
            </div>
            <div className='frame-container'>
                <iframe className="EmailTemplatePreview" id='myFrame' ref={frameRef} onLoad={onPreviewLoad} srcDoc={content}
                    style={{ width: iframeWidth, padding: '2px', userSelect: 'none', pointerEvents: 'none' }}></iframe>
            </div>
        </div>
    );
}
