import { useEffect, useRef, useState } from "react";
import DropdownMenu from "../../utils/Dropdown";

export default function SubjectInput({ defaultValue, items, onChange, errors, onBlur }: any) {
    const subjectEditor: any = useRef(null);

    let lastCursorPos: any = null;

    useEffect(() => {
        // Focus the editor on mount
        if (subjectEditor.current) {
            subjectEditor.current.focus();
        }
    }, []);

    const addDynamicField = (field: any) => {
        // const newSpan = document.createElement('span');
        // newSpan.setAttribute('contenteditable', 'false');
        // newSpan.setAttribute('data-dynamic-field', field.valueName)
        // newSpan.innerHTML = `{${field.name}}`;
        const newField = document.createTextNode(field.valueName);
        const space = document.createTextNode(' ');

        // Ensure the editor is focused before inserting
        if (subjectEditor.current) {
            subjectEditor.current.focus();
        }

        if (lastCursorPos) {
            lastCursorPos.insertNode(newField);
            lastCursorPos.collapse(false);
            lastCursorPos.insertNode(space);
            lastCursorPos.collapse(false);
        } else {
            subjectEditor.current.appendChild(newField);
            subjectEditor.current.appendChild(space);
        }

        // Update the cursor position
        updateCursorAfterInsertion(newField);
        onChange(subjectEditor.current.innerHTML);
        onBlur();
    };

    const updateCursorAfterInsertion = (insertedNode:any) => {
        const range = document.createRange();
        const sel:any = window.getSelection();
        range.setStartAfter(insertedNode);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
    };

    const onPaste = (event: any) => {
        event.preventDefault();
        const clipboardData = event.clipboardData || window.Clipboard;
        const pastedText = clipboardData.getData("text/plain");
        document.execCommand("insertHTML", false, pastedText);
    };

    const storeCursorLocation = () => {
        const sel:any = window.getSelection();
        if (sel.getRangeAt && sel.rangeCount) {
            lastCursorPos = sel.getRangeAt(0);
        }
    };

    const onCEKeydown = (e: any) => {
        if (e.keyCode === 13) {
            e.preventDefault();
        } else if (e.ctrlKey || e.metaKey) {
            switch (e.key) {
                case 'b':
                case 'i':
                case 'u':
                    e.preventDefault();
                    break;
                default:
                    break;
            }
        }
    };

    return (
        <div className="form-group fw-70">
            <label className='d-flex justify-content-between'>
                <div>Email Subject <span className="required">*</span></div><DropdownMenu onSelect={addDynamicField} items={items} />
            </label>
            <div
                onKeyUp={storeCursorLocation}
                onMouseUp={storeCursorLocation} // Added to update cursor position on mouse click
                onKeyDown={onCEKeydown}
                dangerouslySetInnerHTML={{ __html: defaultValue }}
                onPaste={onPaste}
                onInput={(event: any) => onChange && onChange(event.target.innerHTML)}
                onBlur={onBlur}
                ref={subjectEditor} className="form-control subject-content" contentEditable>
            </div>
            {errors.Subject && <span className="error">{errors.Subject.message}</span>}
        </div>
    );
}
