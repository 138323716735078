import { useState } from "react";
import UserEmail from "./User";
import AdminEmail from "./Admin";
const EmailSettings = ({ creator, activeEmail}: any) => {

    const [activeType, setActiveType] = useState('');


    const closeSetting = () => {
        setActiveType('');
    }

    const getHeader = () => {
        const content = `${activeEmail ? (activeEmail == 'admin' ? 'Admin ' : 'User ') : ''}Email Settings`;
        // return (activeType ? <span onClick={closeSetting} className="settings-back"><i className="fas fa-arrow-left"></i> {' ' + content}</span> : content);
        return content;
    }

    return (<>
        <div className="settings-header">
            <div className="header-title">{getHeader()}</div>
            {/* {activeType ? <button className="btn" type="submit" form="userForm">Save</button> : ''} */}
        </div>
        {/* {!activeType ? (<div className="email-blocks">
            <div onClick={() => setActiveType('user')} className="icon-block">
                <i className="fas fa-user"></i>
                <p>User Email</p>
            </div>
            <div onClick={() => setActiveType('admin')} className="icon-block">
                <i className="fas fa-user-shield"></i>
                <p>Admin Email</p>
            </div>
        </div>) : ''}  */}
        {activeEmail == 'user' && <UserEmail />}
        {activeEmail == 'admin' && <AdminEmail />}
    </>)
}

export default EmailSettings;