import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_SOCKET_ENDPOINT + 'forms', { autoConnect: false });

socket.on("connect", () => {
	console.log("%c Touchpoint : SOCKET CONNECTED ", "color: red; display: block;");
});

socket.on("disconnect", () => {
	console.log("socket disconnected");
});

export default socket;