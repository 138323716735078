import { ComponentCollection, Serializer, type Question, SvgRegistry } from "survey-core";

SvgRegistry.registerIconFromSvg('icon-email', `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>`);

(<any>ComponentCollection).Instance.add({
    // A unique name; must use lowercase
    name: "emailfield",
    iconName: 'icon-email',
    // A display name used in the Toolbox
    title: "Email",
    // A JSON schema for the base question type (Dropdown in this case)
    questionJSON: {
        "type": "text",
        "inputType": "email",
        "placeholder": "Enter email address"
    },
    onInit() {
        Serializer.addProperty('emailfield', {
            name: "placeholder",
            displayName: 'Placeholder',
            category: 'general',
            type: "string",
            default: 'Enter email address'
        });
    },
    onLoaded(question: Question) {
        if (question) {
            this.updateProperty(question);
        }
    },
    updateProperty(question: Question) {
        if (!!question.contentQuestion)
            question.contentQuestion.placeholder = question.placeholder
    },
    onItemValuePropertyChanged(question: Question, options: any) {
        if (options.propertyName === "placeholder") {
            this.updateProperty(question);
        }
    },
    onPropertyChanged(question: Question, propertyName: string, newValue: any) {
        if (propertyName === "placeholder") {
            this.updateProperty(question);
        }
    }
});
