import React from "react";
import Select from "react-select";

const FieldsMap = ({ field, items, sectionIndex, fieldIndex, section, handleInputChange, handleDeleteField, isFormDisabled, getPreviousSectionIds }: any) => (
    <div className="form-sec">
        <div className="field-bx form-group">
            <label>Form Field</label>
            <Select isClearable={true} isDisabled={isFormDisabled} options={items.concat(getPreviousSectionIds(sectionIndex))} value={field.FormFieldName ? { value: field.FormFieldName, label: items.find((f: any) => f.name == field.FormFieldName) ? items.find((f: any) => f.name == field.FormFieldName).label : field.FormFieldName } : ""} onChange={(e: any) => handleInputChange(sectionIndex, fieldIndex, "FormFieldName", e ? e.value : "")} />
            {section.validationMessages.FieldsMap[fieldIndex].FormFieldName && <div className="error">{section.validationMessages.FieldsMap[fieldIndex].FormFieldName}</div>}
        </div>
        <div className="Optimiser-bx form-group">
            <label>Optimiser Field</label>
            <Select isClearable={true} isDisabled={isFormDisabled} options={section.ObjectFields} value={field.OptimiserFieldName ? { value: field.OptimiserFieldName, label: section.ObjectFields.find((f: any) => f.value == field.OptimiserFieldName).label } : ""} onChange={(e: any) => handleInputChange(sectionIndex, fieldIndex, "OptimiserFieldName", e ? e.value : "")} />
            {section.validationMessages.FieldsMap[fieldIndex].OptimiserFieldName && <div className="error">{section.validationMessages.FieldsMap[fieldIndex].OptimiserFieldName}</div>}
        </div>
        {
            <div className={"icon-bx text-left" + " " + (isFormDisabled && "is-disabled")} style={{ marginTop: "32px" }}>
                <i className="fa fa-trash-alt trash-icon" onClick={() => handleDeleteField(sectionIndex, fieldIndex)}></i>
            </div>
        }
        <div className="clear"></div>
    </div>
);

export default FieldsMap;
