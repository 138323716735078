import { useEffect, useState } from "react";


export default function useEmailTab() {
    const [activeTab, setActiveTab] = useState('setting');

    return {
        activeTab, EmailTab: () => (<div className="svc-tabbed-menu-wrapper mb-2 email-setting-tabs">
            <div className="svc-tabbed-menu svc-tabbed-menu-1">
                <span className="svc-tabbed-menu-item-container" onClick={() => setActiveTab('setting')}>
                    <div className="sv-action__content">
                        <div className={`svc-tabbed-menu-item ${activeTab == 'setting' ? 'svc-tabbed-menu-item--selected' : ''}`}>
                            <span className="svc-text- svc-tabbed-menu-item__text svc-text--normal svc-text--bold">Settings</span>
                        </div>
                    </div>
                </span>
                <span className="svc-tabbed-menu-item-container tab-seperator" onClick={() => setActiveTab('email')}>
                    <div className="sv-action__content">
                        <div className={`svc-tabbed-menu-item ${activeTab != 'setting' ? 'svc-tabbed-menu-item--selected' : ''}`}>
                            <span className="svc-text- svc-tabbed-menu-item__text svc-text--normal svc-text--bold">Email</span>
                        </div>
                    </div>
                </span>
            </div>
        </div>)
    }
}