import React, { useState, useEffect, CSSProperties } from 'react';
import CreateRecord from './create/CreateRecord'; // Component from step 1
import UpdateRecord from './update/UpdateRecord'; // Component for step 2
import useAxios from '../../../../hooks/useAxios';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, updateIntegration } from '../../../../store';
import PulseLoader from "react-spinners/PulseLoader";
const OptmiserIntegration = (creator: any) => {
  //const [sections, setSections] = useState([initialSection]);
  const axios = useAxios();
  const [currentStep, setCurrentStep] = useState(1); // Step navigation
  const [optimiserObjects, setoptimiserObjects] = useState([]);
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const dispatch = useDispatch();

  const handleNextStep = (state: number) => {
    setCurrentStep(state);
  };
  const setFormEditable = (state: boolean) => {
    setIsFormDisabled(!state);
  }
  const integrationData: any = useSelector((state: RootState) => state.project.integration);
  useEffect(() => {
    const fetchOptions = async () => {
      //setIsLoading(true);
      try {
        // Replace with your actual API endpoint
        const response = await axios.post(`forms/getobjects`);
        let cdata = response.data.map((d: any) => ({ ...d, value: d.Name, label: d.DisplayName }))
        setoptimiserObjects(cdata);
        dispatch(updateIntegration({ ObjectsList: cdata }));
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        //setIsLoading(false);
      }
    };

    if (!integrationData.ObjectsList) fetchOptions();
    else setoptimiserObjects(integrationData.ObjectsList)
    //if (integrationData && integrationData.create) setSections(integrationData.create);
    //if (integrationData && integrationData.action) setActionName(integrationData.action);
  }, []);
  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
    <div>
      <section className="container optimiser-integration-tab">
        <div className="row">
          <div className="col">
            {optimiserObjects.length ? (
              currentStep === 1 ? (
                <>
                  <CreateRecord
                    handleNextStep={handleNextStep}
                    optimiserObjects={optimiserObjects}
                    setFormEditable={setFormEditable}
                  />
                </>
              ) : <>
                <UpdateRecord
                  handleNextStep={handleNextStep}
                  optimiserObjects={optimiserObjects}
                  isFormDisabled={isFormDisabled}
                  setFormEditable={setFormEditable}
                />
              </>
            ) : (
              <PulseLoader
              color={"#075da8"}
              loading={true}
              size={20}
              aria-label="Loading Spinner"
              id="integration-loader"
            />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OptmiserIntegration;
