import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';

const useAxios = (): AxiosInstance => {
	const instance = axios.create({
		baseURL: process.env.REACT_APP_API_BASE_URL,
	});

	// Request Interceptor
	instance.interceptors.request.use((config: AxiosRequestConfig): any => {
		const params = new URL(document.location.href).searchParams;
		const wbid = params.get("wbid");

		// Redirect if 'wbid' is not present
		if (!wbid) {
			window.location.assign(window.location.origin);
			return config;
		}

		// Append 'wbid' to headers
		config.headers = {
			...config.headers,
			"WBID": wbid,
		};

		return config;
	}, Promise.reject);

	// Response Error Handling
	instance.interceptors.response.use(response => response.data, (error: AxiosError) => {
		// Custom error handling based on the response
		if (error.response) {
			const { status, data }: any = error.response;

			if (data.errorCode && data.errorCode === "TP_INVALID_TOKEN") {
				// Reload the page for specific error code
				window.location.reload();
			} else {
				// General error handling
				// Implement your error handling logic here
			}
		}

		return Promise.reject(error);
	});

	return instance;
};

export default useAxios;
