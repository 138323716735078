import React, { useEffect, useRef, forwardRef, useImperativeHandle } from "react";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

const PhoneInput = forwardRef(({ PhoneName, PhoneNumber, onInput, onFocus, blurMethod}: any, ref) => {
    const phoneInputRef = useRef<HTMLInputElement>(null);
    const itiInstance = useRef<any>(null);

    useImperativeHandle(ref, () => ({
        getPhoneNumber: () => {
            return itiInstance.current ? itiInstance.current.getNumber() : "";
        }
    }));

    useEffect(() => {
        if (phoneInputRef.current) {
            itiInstance.current = intlTelInput(phoneInputRef.current, {
                nationalMode: false,
                separateDialCode: true,
                initialCountry: "gb",
            });

            // Set initial phone number if provided
            if (PhoneNumber) {
                itiInstance.current.setNumber(PhoneNumber);
            }
        }

        return () => {
            if (itiInstance.current) {
                itiInstance.current.destroy();
            }
        };
    }, [PhoneNumber]);

    const handleInput = () => {
        if (itiInstance.current) {
            const num = itiInstance.current.getNumber();
            onInput(num);
        }
    };

    const handleFocus = () => {
        if (itiInstance.current) {
            const num = itiInstance.current.getNumber();
            onInput(num);
            onFocus(PhoneName);
        }
    };

    return (
        <input
            type="text"
            className="phoneformcontrol"
            id={PhoneName}
            ref={phoneInputRef}
            onInput={handleInput}
            onFocus={handleFocus}
            phone-name={PhoneName}
            onBlur={blurMethod}
        />
    );
});

export default PhoneInput;
