import React, { useState, useEffect } from "react";
import { List, arrayMove } from "react-movable";
import useAxios from "../../../hooks/useAxios";
import loaderImage from '../../../assets/loader-lg.gif';

const GridSetting = ({ columns, formId, setIsGridSettingsOpen, fetchSettings, gridSettingsData }: any) => {
    const axios = useAxios();
    const [gridSettings, setGridSettings] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        gridSettingsData.length ? setGridSettings(gridSettingsData) : resetColumns();
    }, []);

    const resetColumns = () => {
        const gridColumns = columns.map((col: any) => ({
            field: col.field,
            isDisabled: col.isDisabled === true ? true : false,
            isVisible: col.isVisible === true ? true : false,
            label: col.label,
            isFixed: col.isFixed === true ? true : false,
            columnSearch: col.columnSearch ? true : false,
            sequence: col.sequence,
            width: col.width ? col.width : '',
        }));
        setGridSettings(gridColumns);
    };

    const saveGridSettings = async () => {
        setLoading(true);
        const cols:any = gridSettings.map((col: any, index: any) => {return { ...col, sequence: index }});
        await axios.put('common/grid-setting', { PageType: "FormRecords_" + formId, Columns: cols });
        fetchSettings();
        setIsGridSettingsOpen(false);
        setLoading(false);
    };

    const handleVisibilityChange = (index: any) => {
        const newData: any = [...gridSettings];
        newData[index].isVisible = !newData[index].isVisible;
        setGridSettings(newData);
    };

    const onPositionChange = (settings:any) =>{
        setGridSettings(settings)
    }

    return (
        <>
            {loading && (
                <div className="loader-overlay">
                    <img src={loaderImage} alt="Loading..." />
                </div>
            )}
            <div className="modal-overlay">
                <div className="modal">
                    <div className="modal-header">
                        <div className="modal-close" data-toggle="tooltip" data-placement="bottom" title="Close"
                            data-dismiss="modal" aria-label="Close" onClick={() => setIsGridSettingsOpen(false)}>
                            <i className="fa fa-times closeicon" aria-hidden="true"></i>
                        </div>
                        <h5 className="modal-title" id="modalTitle">Grid Setting</h5>
                        <hr className="hr-width" />

                        <div className="modal-body">
                            <div className="maindv">

                                <table className="grid-settings-table">
                                    <thead>
                                        <tr className="hrow">
                                            <th>Position</th>
                                            <th>Column Name</th>
                                            <th>Display</th>
                                        </tr>
                                    </thead>

                                    <List
                                        values={gridSettings}
                                        onChange={({ oldIndex, newIndex }: any) => {
                                            onPositionChange(arrayMove(gridSettings, oldIndex, newIndex));
                                        }}
                                        renderList={({ children, props }: any) => <tbody {...props}>{children}</tbody>}
                                        renderItem={({ value, props, index }: any) => {
                                            const { key, ...restProps } = props;
                                            return (
                                                <tr key={key} {...restProps}>
                                                    <td className="position-handle">
                                                        {"☰"}
                                                    </td>
                                                    <td>{value.label}</td>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={value.isVisible}
                                                            disabled={value.isDisabled}
                                                            onChange={() => handleVisibilityChange(index)}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        }}
                                    />
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={resetColumns} className="btn-secondary1 ml-2">Reset</button>
                            <button type="button" onClick={saveGridSettings} className="btn-primary ml-2">Save</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default GridSetting;
