import { ComponentCollection, Serializer, SvgRegistry } from "survey-core";

SvgRegistry.registerIconFromSvg("icon-fullname", `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M406.5 399.6C387.4 352.9 341.5 320 288 320H224c-53.5 0-99.4 32.9-118.5 79.6C69.9 362.2 48 311.7 48 256C48 141.1 141.1 48 256 48s208 93.1 208 208c0 55.7-21.9 106.2-57.5 143.6zm-40.1 32.7C334.4 452.4 296.6 464 256 464s-78.4-11.6-110.5-31.7c7.3-36.7 39.7-64.3 78.5-64.3h64c38.8 0 71.2 27.6 78.5 64.3zM256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-272a40 40 0 1 1 0-80 40 40 0 1 1 0 80zm-88-40a88 88 0 1 0 176 0 88 88 0 1 0 -176 0z"/></svg>`);

const WIDGET_NAME = 'fullname';

const fullNameFields = [
    {
        name: "prefixDropdown",
        title: "Prefix",
        isRequired: false,
        type: 'dropdown',
        visibleIf: (obj: any) => obj.prefixFieldType === 'dropdown',
        choices: ['Mr', 'Mrs', 'Ms'],
    },
    {
        name: "prefixText",
        title: "Prefix",
        isRequired: false,
        type: 'text',
        visibleIf: (obj: any) => obj.prefixFieldType === 'text',
        placeholder: "Enter prefix",
        maxLength: 100
    },
    {
        name: "firstName",
        title: "First Name",
        isRequired: false,
        required: false,
        type: 'text',
        placeholder: "Enter first name",
        maxLength: 100
    },
    {
        name: "middleName",
        title: "Middle Name",
        isRequired: false,
        type: 'text',
        placeholder: "Enter middle name",
        maxLength: 100
    },
    {
        name: "lastName",
        title: "Last Name",
        isRequired: false,
        type: 'text',
        placeholder: "Enter last name",
        maxLength: 100
    }
];

ComponentCollection.Instance.add({
    name: WIDGET_NAME,
    title: 'Full Name',
    iconName: 'icon-fullname',
    elementsJSON: fullNameFields,
    onInit() {

        Serializer.addProperty(WIDGET_NAME, {
            name: `prefixLabel`,
            type: 'string',
            displayName: "Prefix",
            category: 'Labels',
            default: 'Prefix'
        })

        // To Select prefix field type
        Serializer.addProperty(WIDGET_NAME, {
            name: "prefixFieldType",
            type: 'string',
            displayName: "Prefix Field Type",
            visibleIf: (obj: any) => obj.prefixVisible,
            category: 'Visibility',
            choices: ['dropdown', 'text'],
            default: 'dropdown'
        });

        Serializer.addProperty(WIDGET_NAME, {
            name: `prefixPlaceholder`,
            type: 'string',
            displayName: "Prefix",
            visibleIf: (obj: any) => obj.prefixFieldType === 'text',
            category: 'Placeholders',
            default: 'Enter prefix'
        })


        Serializer.addProperty(WIDGET_NAME, {
            name: `prefixVisible`,
            type: 'boolean',
            displayName: "Prefix",
            category: 'Visibility',
            default: false
        })



        // To add option for prefix choice if its dropdown
        Serializer.addProperty(WIDGET_NAME, {
            name: "prefixChoices",
            type: 'itemvalues',
            category: 'Visibility',
            displayName: "Prefix Choices",
            visibleIf: (obj: any) => obj.prefixVisible && obj.prefixFieldType === 'dropdown',
            default: [
                { "value": "mr", "text": "Mr" },
                { "value": "mrs", "text": "Mrs" },
                { "value": "ms", "text": "Ms" }
            ]
        });

        Serializer.addProperty(WIDGET_NAME, {
            name: `prefixRequired`,
            type: 'boolean',
            displayName: "Prefix",
            visibleIf: (obj: any) => obj.prefixVisible,
            category: 'Required Fields',
            default: false
        })

        fullNameFields.forEach((field) => {

            // Labels
            if (field.title !== 'Prefix') {
                Serializer.addProperty(WIDGET_NAME, {
                    name: `${field.name}Label`,
                    type: 'string',
                    displayName: field.title,
                    category: 'Labels',
                    default: field.title
                })

                // Placeholders
                Serializer.addProperty(WIDGET_NAME, {
                    name: `${field.name}Placeholder`,
                    type: 'string',
                    displayName: field.title,
                    category: 'Placeholders',
                    default: field.placeholder
                })

                // Visibility
                if (field.name === 'middleName') {
                    Serializer.addProperty(WIDGET_NAME, {
                        name: `${field.name}Visible`,
                        type: 'boolean',
                        category: 'Visibility',
                        displayName: field.title,
                        default: false
                    })
                }

                // Required
                // if (field.name === 'firstName') {
                Serializer.addProperty(WIDGET_NAME, {
                    name: `${field.name}Required`,
                    type: 'boolean',
                    displayName: field.title,
                    category: 'Required Fields',
                    default: field.required || false
                })
                // }
            }
        })
    },
    onLoaded(question) {
        question.title = question.title || 'Full Name'
        fullNameFields.forEach((field) => {
            const quest = question.contentPanel?.getQuestionByName(field.name);
            if (quest) {

                let customPlaceholder = question[`${field.name}Placeholder`];
                let customIsRequired = question[`${field.name}Required`];
                quest.placeholder = customPlaceholder ? customPlaceholder : field.placeholder;
                quest.isRequired = customIsRequired !== undefined ? customIsRequired : field.isRequired;

                let customLabel = question[`${field.name}Label`];

                if (field.title === 'Prefix') {
                    const cstmVis = question['prefixVisible'] !== undefined ? question['prefixVisible'] : false;
                    quest.title = customLabel || field.title;
                    if (cstmVis) {
                        if (question.prefixFieldType === 'text' && field.name === 'prefixText') {
                            quest.visible = true;
                            question.contentPanel.getQuestionByName('prefixDropdown').visible = false;
                        } else if (field.name === 'prefixDropdown') {
                            quest.visible = true;
                            question.contentPanel.getQuestionByName('prefixText').visible = false;
                        } else {
                            quest.visible = false;
                        }
                    } else {
                        quest.visible = false;
                    }

                    quest.placeholder = question.prefixPlaceholder || 'Enter prefix'
                    quest.isRequired = question.prefixRequired || false;
                    if (field.name === 'prefixDropdown') {
                        quest.choices = question.prefixChoices || [
                            { "value": "mr", "text": "Mr" },
                            { "value": "mrs", "text": "Mrs" },
                            { "value": "ms", "text": "Ms" }
                        ];
                    }
                    quest.title = question.prefixLabel ? question.prefixLabel : quest.title;
                } else if (field.name == 'middleName') {
                    let isVisible = question[`middleNameVisible`];
                    quest.visible = isVisible;
                    quest.title = customLabel ? customLabel : quest.title;
                } else {
                    if (customLabel !== undefined) {
                        quest.title = customLabel;
                    }
                }
            }
        })
        // updateFieldLayout(question);
    },
    onPropertyChanged(question, propertyName, newValue) {
        if (question.contentPanel) {
            const prefixDropdown = question.contentPanel.getQuestionByName('prefixDropdown');
            const prefixText = question.contentPanel.getQuestionByName('prefixText');
            const middleName = question.contentPanel.getQuestionByName('middleName');
            switch (propertyName) {
                case 'prefixFieldType':
                    prefixDropdown.visible = newValue === 'dropdown'
                    prefixText.visible = newValue === 'text';
                    break;

                default:
                    break;
            }
            // Update visibility based on prefixFieldType
            if (prefixDropdown && prefixText) {
                if (propertyName === 'prefixFieldType') {
                    prefixDropdown.visible = newValue === 'dropdown';
                    prefixText.visible = newValue === 'text';
                }
            }

            // Update labels
            if (propertyName.endsWith('Label')) {
                const fieldName = propertyName.replace('Label', '');
                if (propertyName.startsWith('prefix')) {
                    prefixText.title = newValue || prefixText.jsonObj.title;
                    prefixDropdown.title = newValue || prefixDropdown.jsonObj.title;
                } else {
                    const field = question.contentPanel.getQuestionByName(fieldName);
                    if (field) {
                        field.title = newValue || field.jsonObj.title;
                    }
                }
            }

            // Update placeholders
            if (propertyName.endsWith('Placeholder')) {
                const fieldName = propertyName.replace('Placeholder', '');
                if (propertyName.startsWith('prefix')) {
                    prefixText.placeholder = newValue || prefixText.jsonObj.placeholder;
                } else {
                    const field = question.contentPanel.getQuestionByName(fieldName);
                    if (field) {
                        field.placeholder = newValue || field.jsonObj.placeholder;
                    }
                }

            }

            // Required fields
            if (propertyName.endsWith('Required')) {
                if (propertyName.startsWith('prefix')) {
                    prefixDropdown.isRequired = newValue;
                    prefixText.isRequired = newValue;
                } else {
                    const fieldName = propertyName.replace('Required', '');
                    const field = question.contentPanel.getQuestionByName(fieldName);
                    if (field) {
                        field.isRequired = newValue;
                    }
                }
            }

            // Update visibility of other fields
            if (propertyName === 'middleNameVisible') {
                middleName.visible = newValue;
            }
            if (propertyName === 'prefixVisible') {
                if (question.prefixFieldType == 'dropdown') {
                    prefixDropdown.visible = newValue;
                    prefixText.visible = false;
                } else {
                    prefixText.visible = newValue;
                    prefixDropdown.visible = false;
                }
            }

            if (propertyName == 'prefixChoices') {
                prefixDropdown.choices = newValue;
            }
        }

    },
})

function updateFieldLayout(question: any) {
    let visibleFieldCount = 0;

    fullNameFields.forEach(fc => {
        let field = question.contentPanel.getQuestionByName(fc.name);
        if (field) {
            // Set startWithNewLine based on the number of visible fields before this one
            field.startWithNewLine = (visibleFieldCount % 2 === 0);
            // Increment count if the field is visible
            if (field.visible) {
                visibleFieldCount++;
            }
        }
    });
}
