import React, { useEffect, useMemo, useState } from "react";
import {
    useTable,
    useSortBy,
    usePagination,
    useResizeColumns,
    useFlexLayout,
    useFilters,
    useRowSelect,
} from "react-table";
import Select from "react-select";

const DataGrid = ({
    columns,
    data,
    pageIndex,
    pageSize,
    totalRecord,
    onPageChange,
    onPageSizeChange,
    onSelectionChange,
    onSearchListChange,
    searchFormRecords,
    gridRefresh,
    highlight,
    setHighlight,
    viewDetails,
}: any) => {
    const [searchList, setSearchList]: any = useState({});
    const [searchJson, setSearchJson]: any = useState({});

    const actionColumn = {
        Header: () => <span className="grid-col-freeze">Action</span>,
        accessor: 'action',
        id: 'action',
        Cell: ({ row }: any) => (
            <div className="eyeicon tooltip-container" onClick={() => viewDetails(row.original)}>
                <i className="fa fa-eye"></i>
            </div>
        ),
        disableFilters: true,
        disableSortBy: true,
        width: 60,
    };
    const modifiedColumns = useMemo(() => [...columns, actionColumn], [
        columns,
    ]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        selectedFlatRows,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex: tablePageIndex, pageSize: tablePageSize },
        setFilter,
    } = useTable(
        {
            columns: modifiedColumns,
            data,
            initialState: { pageIndex, pageSize },
            manualPagination: true,
            pageCount: Math.ceil(totalRecord / pageSize),
        },
        useFilters,
        useSortBy,
        usePagination,
        useFlexLayout,
        useResizeColumns,
        useRowSelect,
        (hooks: any) => {
            hooks.visibleColumns.push((columns: any) => [
                {
                    id: "selection",
                    Header: ({ getToggleAllPageRowsSelectedProps }: any) => {
                        const allPageRowsSelectedProps = getToggleAllPageRowsSelectedProps();
                        return (
                            <div>
                                <input
                                    type="checkbox"
                                    {...allPageRowsSelectedProps}
                                    indeterminate={
                                        allPageRowsSelectedProps.indeterminate ? "true" : undefined
                                    }
                                />
                            </div>
                        );
                    },
                    Cell: ({ row }: any) => {
                        const rowSelectedProps = row.getToggleRowSelectedProps();
                        return (
                            <div>
                                <input
                                    type="checkbox"
                                    {...rowSelectedProps}
                                    indeterminate={
                                        rowSelectedProps.indeterminate ? "true" : undefined
                                    }
                                />
                            </div>
                        );
                    },
                    disableSortBy: true,
                    disableFilters: true,
                    width: 50,
                },
                ...columns,
            ]);
        }
    );

    useEffect(() => {
        onSelectionChange(selectedFlatRows.map((row: any) => row.original));
    }, [selectedFlatRows]);

    useEffect(() => {
        setSearchJson({});
        setSearchList({})
        setHighlight(false);
    }, [gridRefresh])

    const onDateChange = (field: any, key: string, event: any) => {
        if (field && event.target.value) {
            setSearchList((prevList: any) => ({
                ...prevList,
                [field]: {
                    ...prevList[field],
                    [key]: event.target.value
                }
            }));
            setSearchJson((prevList: any) => ({
                ...prevList,
                [field]: {
                    ...prevList[field],
                    [key]: event.target.value
                }
            }));
        }

    };

    const onChangeFilterForSelect = (field: any, value: any) => {
        if (field) {
            if (Array.isArray(value) && value.length === 0) {
                setSearchJson((prevList: any) => {
                    const { [field]: _, ...rest } = prevList;
                    return rest;
                });
            } else if (value === null || typeof value === "undefined") {
                setSearchJson((prevList: any) => {
                    const { [field]: _, ...rest } = prevList;
                    return rest;
                });
            } else if (Array.isArray(value)) {
                setSearchJson((prevList: any) => ({
                    ...prevList,
                    [field]: value
                }));
            } else if (typeof value === "object") {
                let obj: any = {};
                obj["Key"] = value.label;
                obj["Value"] = value.value;
                setSearchJson((prevList: any) => ({
                    ...prevList,
                    [field]: obj
                }));
            } else {
                setSearchJson((prevList: any) => ({
                    ...prevList,
                    [field]: value
                }));
            }
        }
    }

    const onChangeFilter = (field: any, value: any) => {
        setSearchJson((prevList: any) => ({
            ...prevList,
            [field]: value
        }));
        setSearchList((prevList: any) => ({
            ...prevList,
            [field]: value
        }));
    }

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            searchFormRecords();
            setHighlight(true);
        }
    }

    useEffect(() => {
        onSearchListChange(searchJson);
    }, [searchJson]);

    const renderFilter = (column: any) => {
        const { id, type, choices, inputType } = column;

        if (id === "tp_serialNumber") return null;

        if (inputType === "date") {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <input
                        type="date"
                        className="inputtext"
                        value={searchList[id]?.from || ""}
                        onChange={(event) => onDateChange(id, "from", event)}
                    />
                    <span style={{ margin: '0 5px' }}>-</span>
                    <input
                        type="date"
                        className="inputtext"
                        value={searchList[id]?.to || ""}
                        onChange={(event) => onDateChange(id, "to", event)}
                    />
                </div>
            )
        }

        switch (type) {
            case "tagbox":
            case "checkbox":
                return (
                    <Select
                        isMulti
                        value={searchList[id]}
                        onChange={(value: any) => onChangeFilterForSelect(id, value.map((v: any) => v.value))}
                        options={choices.map((choice: any) => ({ value: choice.Key, label: choice.Value }))}
                    />
                );
            case "dropdown":
                return (
                    <Select
                        isClearable
                        value={searchList[id]}
                        onChange={(value: any) => onChangeFilterForSelect(id, value)}
                        options={choices.map((choice: any) => ({ value: choice.Key, label: choice.Value }))}
                    />
                );
            case "CreatedAt":
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="date"
                            className="inputtext"
                            value={searchList[id]?.from || ""}
                            onChange={(event) => onDateChange(id, "from", event)}
                        />
                        <span style={{ margin: '0 5px' }}>-</span>
                        <input
                            type="date"
                            className="inputtext"
                            value={searchList[id]?.to || ""}
                            onChange={(event) => onDateChange(id, "to", event)}
                        />
                    </div>
                );
            case "datepicker":
                return (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="date"
                            className="inputtext"
                            value={searchList[id]?.from || ""}
                            onChange={(event) => onDateChange(id, "from", event)}
                        />
                        <span style={{ margin: '0 5px' }}>-</span>
                        <input
                            type="date"
                            className="inputtext"
                            value={searchList[id]?.to || ""}
                            onChange={(event) => onDateChange(id, "to", event)}
                        />
                    </div>
                );
            case "privacypolicy":
            case "termsconditions":
            case "boolean":
                return (
                    <Select
                        isClearable
                        value={searchList[id]}
                        onChange={(value: any) => onChangeFilterForSelect(id, value ? value.value : null)}
                        options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' }
                        ]}
                    />
                );
            case "file":
                return (
                    <Select
                        isClearable
                        onChange={(value: any) => onChangeFilterForSelect(id, value ? value.value : null)}
                        value={searchList[id]}
                        options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' }
                        ]}
                    />
                );
            default:
                return (
                    <input className="inputtext"
                        onChange={(e) => onChangeFilter(id, e.target.value || undefined)}
                        value={searchList[id] || ''}
                        onKeyDown={handleKeyDown}
                    />
                );
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const totalPageNumbers = 5;

        if (pageCount <= totalPageNumbers) {
            for (let i = 0; i < pageCount; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => {
                            gotoPage(i);
                            onPageChange(i);
                        }}
                        className={tablePageIndex === i ? "active" : ""}
                    >
                        {i + 1}
                    </button>
                );
            }
        } else {
            let startPage = Math.max(0, tablePageIndex - Math.floor(totalPageNumbers / 2));
            let endPage = startPage + totalPageNumbers - 1;

            if (endPage >= pageCount) {
                endPage = pageCount - 1;
                startPage = endPage - totalPageNumbers + 1;
            }

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        onClick={() => {
                            gotoPage(i);
                            onPageChange(i);
                        }}
                        className={tablePageIndex === i ? "active" : ""}
                    >
                        {i + 1}
                    </button>
                );
            }

            if (startPage > 0) {
                pageNumbers.unshift(<span key="ellipsis1">...</span>);
                pageNumbers.unshift(
                    <button
                        key={0}
                        onClick={() => {
                            gotoPage(0);
                            onPageChange(0);
                        }}
                        className={tablePageIndex === 0 ? "active" : ""}
                    >
                        1
                    </button>
                );
            }

            if (endPage < pageCount - 1) {
                pageNumbers.push(<span key="ellipsis2">...</span>);
                pageNumbers.push(
                    <button
                        key={pageCount - 1}
                        onClick={() => {
                            gotoPage(pageCount - 1);
                            onPageChange(pageCount - 1);
                        }}
                        className={tablePageIndex === pageCount - 1 ? "active" : ""}
                    >
                        {pageCount}
                    </button>
                );
            }
        }

        return pageNumbers;
    };

    const highlightText = (cell: any) => {
        let searchStr = searchJson[cell.column.id];
        if (cell.column.type === "datepicker" || cell.column.inputType === "date" || cell.column.id === "CreatedAt") return cell.render("Cell");
        if (searchStr) {
            if (Array.isArray(cell.value) && cell.column.type != "file") {
                let v = cell.value.map((item: any) => item === searchStr[0]);
                const regex = new RegExp(`(${searchStr[0]})`, 'gi');
                const parts = v && searchStr[0].toString().split(regex);
                const data = parts.map((part: any, index: any) => part === searchStr[0] ?
                    (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                    : (part));
                return data
            } else if (typeof cell.value === 'object') {
                if (cell.column.type === "fullname") {
                    const { prefixDropdown, prefixText, firstName, middleName, lastName } = cell.value;
                    const fullname = (prefixDropdown || "") + " " + (prefixText || "") + " " + (firstName || "") + " " + (middleName || "") + " " + (lastName || "");
                    const regex = new RegExp(`(${searchStr})`, 'gi');
                    const parts = fullname.toString().split(regex);
                    const data = parts.map((part: any, index: any) => part.toLowerCase() === searchStr.toLowerCase() ?
                        (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                        : (part));
                    return data
                }
                if (cell.column.type === "address") {
                    if (!['.city', '.country', '.postalCode', '.state'].includes(cell.column.id)) {
                        const { streetAddressLine1, streetAddressLine2 } = cell.value || {};
                        let address = streetAddressLine1 || '';
                        if (streetAddressLine2) {
                            address += `, ${streetAddressLine2}`;
                        }
                        const regex = new RegExp(`(${searchStr})`, 'gi');
                        const parts = address.toString().split(regex);
                        const data = parts.map((part: any, index: any) => part.toLowerCase() === searchStr.toLowerCase() ?
                            (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                            : (part));
                        return data
                    }
                }
            } else if (cell.column.type === "file") {
                const value = cell.value ? "Yes" : "No";
                searchStr = searchStr ? "Yes" : "No";
                const regex = new RegExp(`(${searchStr})`, 'gi');
                const parts = value.toString().split(regex);
                const data = parts.map((part: any, index: any) => part === searchStr ?
                    (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                    : (part));
                return data
            } else if (cell.column.type === "boolean" || cell.column.type === "privacypolicy" || cell.column.type === "termsconditions") {
                searchStr = searchStr ? "Yes" : "No";
                const regex = new RegExp(`(${searchStr})`, 'gi');
                let value = cell.value ? "Yes" : "No"
                const parts = value.toString().split(regex);
                const data = parts.map((part: any, index: any) => part === searchStr ?
                    (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                    : (part));
                return data
            } else if(cell.column.type === "dropdown" && cell.value){       
                    searchStr = searchStr.Value
                    const regex = new RegExp(`(${searchStr})`, 'gi');
                    const parts = cell.value.toString().split(regex);
                    const data = parts.map((part: any, index: any) => part === searchStr ?
                        (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                        : (part));
                    return data
                
            } else {
                if (!searchStr) return cell.value;
                const regex = new RegExp(`(${searchStr})`, 'gi');
                if (cell.value) {
                    const parts = cell.value.toString().split(regex);
                    const data = parts.map((part: any, index: any) => part.toLowerCase() === searchStr.toLowerCase() ?
                        (<span key={index} style={{ backgroundColor: '#fc0' }}> {part.toString()} </span>)
                        : (part));
                    return data
                }
            }
        } else {
            return cell.render("Cell");
        }
    };
    return (
        <>
            <div className="table-responsive grid-table-container border_table">
                <table {...getTableProps()} className="table table-bordered">
                    <thead>
                        {headerGroups.map((headerGroup: any, headerGroupIndex: number) => {
                            const { key, ...headerGroupProps } = headerGroup.getHeaderGroupProps();
                            return (
                                <React.Fragment key={`headerGroup-${headerGroupIndex}`}>
                                    <tr {...headerGroupProps} className="head-row title">
                                        {headerGroup.headers.map((column: any, columnIndex: number) => {
                                            const { key: columnKey, ...columnProps } = column.getHeaderProps();
                                            return (
                                                <th
                                                    key={`header-${headerGroupIndex}-${columnIndex}`}
                                                    {...columnProps}
                                                    className="header-name">
                                                    {column.render("Header")}
                                                    {!column.disableSortBy && (
                                                        <i
                                                            className={`myIcon fas fa-sort ${column.isSorted
                                                                ? column.isSortedDesc
                                                                    ? "sort-desc"
                                                                    : "sort-asc"
                                                                : ""}`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                column.toggleSortBy(!column.isSortedDesc);
                                                            }}
                                                        ></i>
                                                    )}
                                                    <div
                                                        {...column.getResizerProps()}
                                                        className={`resizer ${column.isResizing ? "isResizing" : ""}`}
                                                    />
                                                </th>
                                            );
                                        })}
                                    </tr>
                                    <tr key={`filterRow-${headerGroupIndex}`} {...headerGroupProps} className="head-row">
                                        {headerGroup.headers.map((column: any, columnIndex: number) => {
                                            const { key: columnKey, ...columnProps } = column.getHeaderProps();
                                            return (
                                                <th
                                                    key={`filter-${headerGroupIndex}-${columnIndex}`}
                                                    {...columnProps}
                                                    className="search-input">
                                                    {column.canFilter ? renderFilter(column) : null}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {page.map((row: any, rowIndex: number) => {
                            prepareRow(row);
                            const { key, ...rowProps } = row.getRowProps();
                            return (
                                <tr key={`row-${row.id}`} {...rowProps} >
                                    {row.cells.map((cell: any, cellIndex: number) => {
                                        const { key: cellKey, ...cellProps } = cell.getCellProps();
                                        const cellValue = cell.render("Cell");
                                        return (
                                            <td key={`cell-${row.id}-${cellIndex}`} {...cellProps} className="input-with-ellipsis">
                                                {/* {highlightContent ? <span>{cell.render("Cell")}</span> : cell.render("Cell")} */}
                                                {/* {highlightContent ? highlightText(cell) : cell.render("Cell")} */}
                                                {highlight ? highlightText(cell) : cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="pagination-container">
                <div className="per-page">
                    <span>
                        Per Page{" "}
                        <select
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                                onPageSizeChange(Number(e.target.value));
                            }}
                        >
                            {[10, 15, 20, 25, 30].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </span>
                </div>
                {totalRecord > pageSize && (
                    <div className="pagination-controls">
                        <button
                            onClick={() => {
                                previousPage();
                                onPageChange(tablePageIndex - 1);
                            }}
                            disabled={!canPreviousPage}
                        >
                            Previous
                        </button>
                        {renderPageNumbers()}
                        <button
                            onClick={() => {
                                nextPage();
                                onPageChange(tablePageIndex + 1);
                            }}
                            disabled={!canNextPage}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default DataGrid;
