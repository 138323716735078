import React, { useEffect, useRef, useState } from 'react'
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import { localization } from "survey-creator-core";
import { Action, setLicenseKey, Serializer, JsonMetadata, JsonObject, ChoicesRestful, ItemValue } from 'survey-core'
import { ReactElementFactory } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import SettingsComponent from './settings/Settings';
import PublishComponent from './publish/Publish';
import { useDispatch,useSelector } from 'react-redux';
import { updateMetadata, updateMetadataTheme } from '../store';
import useAxios from '../hooks/useAxios';
import { REQUIRED_ELEMENTS_TYPE } from '../constants';
import 'sweetalert2/src/sweetalert2.scss'
import { Confirm, ConfirmWithYesAndNo } from '../config/swal';
import { generateUniqueValueName, debounce } from '../helpers';
import './translations'
import './widgets/Address';
import './widgets/PhoneNumber';
import './widgets/FullName';
import './widgets/TermsPrivacy';
import './widgets/Label';
import './widgets/Date'
import './widgets/Month'
import './widgets/Email'
import { RootState } from '../store';
//import './widgets/DropdownAndMultiSelect'
export default function SurveyCreatorWidget({ initData, _id, FetchFormData, tab, status }: any) {
    const { Content, Theme } = initData;
    const dispatch = useDispatch();
    const axios = useAxios();
    let desgSave: any = useRef();
    let themeSave: any = useRef();
    const translations = localization.getLocale("");
    translations.ed.surveyTypeName = Content.title;
    let formStatus = status;
    let updatingProperty = false;

    let projectConfig = useSelector((state: RootState) => state.project.projectConfig);
    const license: any = process.env.REACT_APP_CREATOR_LICENSE_KEY;
    setLicenseKey(license);
    const domainUrl = () =>{
        let link = "";
        if (projectConfig.EnvType == "production" && projectConfig.Domain) link = projectConfig.Domain;
        else link = projectConfig.CDNName;
        return link.startsWith("http://") || link.startsWith("https://") ? link : `https://${link}`;
    }
    console.log(domainUrl());
    const creator = new SurveyCreator({
        showLogicTab: true,
        isAutoSave: false,
        showJSONEditorTab: false,
        showThemeTab: true,
        questionTypes: REQUIRED_ELEMENTS_TYPE
    });
    const L1BlackList = [
        'maxTimeToFinish',
        'maxTimeToFinishPage',
        'showTimerPanel',
        'showTimerPanelMode',
        'clearInvisibleValues',
        'textUpdateMode',
        'sendResultOnPageNext',
        'storeOthersAsComment',
        'focusOnFirstError',
        'checkErrorsMode',
        'completedBeforeHtml',
        'loadingHtml'
    ]
    const monthDefaultValue = [
        "January", "February", "March",
        "April", "May", "June",
        "July", "August", "September",
        "October", "November", "December"
    ];
    const fullNameDefautlValue = [
        { "value": "mr", "text": "Mr" },
        { "value": "mrs", "text": "Mrs" },
        { "value": "ms", "text": "Ms" }
    ]

    const L2BlackList: any = [];

    const handleFormStatus = (value: string) => formStatus = value;

    creator.onShowingProperty.add(function (_, options) {

        switch (options.obj.getType()) {
            case 'survey':
                options.canShow = L1BlackList.indexOf(options.property.name) < 0;
                break;
            case 'file':
                options.canShow = !['storeDataAsText'].includes(options.property.name)
                break;
            case 'address':
            case 'fullname':
                options.canShow = !['isRequired'].includes(options.property.name)
                break;
            case 'customlabel':
                options.canShow = !['isRequired', 'readOnly', 'description', 'name', 'title'].includes(options.property.name)
                break;
            default:
                options.canShow = L2BlackList.indexOf(options.property.name) < 0;
                break;
        }
    });

    let isDeleting = false;
    const handleElementDelete = (_: any, options: any) => {
        if (isDeleting) return;
        if (formStatus == 'Draft') return;
        options.allowing = false;

        Confirm({
            width: '570px',
            html: `
            <h3 class="delete-header-text">Are you sure you want to delete this field?</h3>
                <p style="margin-top:0;">
                    Once deleted you will lose data collected from this field.
                </p>
                <p class="delete-info">
                  <i class="fa fa-info-circle"></i>  To avoid data loss you can also Hide the field, in field settings. Upon hiding, the field will not show on the form.
                </p>
            `,
        }).then(({ isConfirmed }) => {
            if (isConfirmed) {
                isDeleting = true;
                creator.deleteElement(options.element);
                isDeleting = false;
            }
        });
    }

    creator.onElementDeleting.add(handleElementDelete);

    const NEW_ORDER = [
        { name: "fullname", category: "Widgets" },
        { name: "emailfield", category: "Widgets" },
        { name: "phonenumber", category: "Widgets" },
        { name: "datepicker", category: "Widgets" },
        { name: "address", category: "Widgets" },
        { name: "termsconditions", category: "Widgets" },
        // { name: "privacypolicy", category: "Widgets" },
        { name: "customlabel", category: "Widgets" },
        { name: "text", category: "Elements" },
        { name: "comment", category: "Elements" },
        { name: "monthfield", category: "Elements" },
        { name: "image", category: "Elements" },
        { name: "dropdown", category: "Elements" },
        { name: "tagbox", category: "Elements" },
        { name: "checkbox", category: "Elements" },
        { name: "file", category: "Elements" },
        { name: "boolean", category: "Elements" },
        { name: "radiogroup", category: "Elements" },
        { name: "html", category: "Elements" },
        // { name: "panel", category: "Elements" }
    ]

    creator.toolbox.changeCategories(NEW_ORDER);

    creator.toolbox.orderedQuestions = NEW_ORDER.map(ne => ne.name)

    ReactElementFactory.Instance.registerElement("svc-tab-settings",
        (props) => React.createElement(() => <SettingsComponent creator={creator} />, props)
    );
    ReactElementFactory.Instance.registerElement("svc-tab-publish",
        (props) => React.createElement(() => <PublishComponent setFormStatus={handleFormStatus} creator={creator} />, props)
    );

    const ConfirmTabSwitch = async (from: string, to: string) => {
        const { isConfirmed } = await ConfirmWithYesAndNo({
            html: "<span style='font-size:15px; color:#686868;'>Changes made have not been saved. Do you want to save the changes?</span>"
        })
        if (isConfirmed) {
            if (from === "theme") {
                await updateMetadataTheme({ _id, patchData: creator.theme, axios })(dispatch);
                // await FetchFormData(to);
            }
            if (from === "designer") {
                await updateMetadata({ _id, patchData: creator.JSON, axios })(dispatch);
            }
        }
        await FetchFormData(to);
    }

    creator.saveSurveyFunc = async (saveNo: number, callback: Function) => {
        await updateMetadata({ _id, patchData: creator.JSON, axios, callback })(dispatch);
    }

    creator.saveThemeFunc = (saveNo: number, callback: Function) => {
        updateMetadataTheme({ _id, patchData: creator.theme, axios, callback })(dispatch);
    }

    const templatesPlugin: any = { activate: () => { }, deactivate: () => { return true; } };


    creator.addPluginTab("settings", templatesPlugin, "Settings", "svc-tab-settings", 4);
    creator.addPluginTab("publish", templatesPlugin, "Publish", "svc-tab-publish", 5);
    creator.toolbarItems.push(new Action({
        visible: false,
        iconName: `icon-moderncheck`,
        iconSize: 25,
        id: "save-status",
        css: "btn-outline-secondary active",
        action: () => { },
    }))
    if (tab === "test") {
        creator.toolbarItems.push(new Action({
            id: "svd-preview",
            visible: true,
            css: '',
            title: '',
            iconName: 'icon-closecamera',
            tooltip: 'Close Preview',
            iconSize: 22,
            action: () => {
                creator.activeTab = "designer";
            }
        }))
    } else {
        creator.toolbarItems.push(new Action({
            id: "svd-preview",
            visible: true,
            css: 'btn-outline',
            title: 'Preview',
            tooltip: 'Preview form',
            iconSize: 24,
            action: () => creator.showPreview()
        }))
    }


    creator.toolbox.showCategoryTitles = true;
    creator.toolbox.allowExpandMultipleCategories = true;
    creator.toolbox.expandAllCategories()
    creator.toolbox.forceCompact = false;
    creator.toolbox.isResponsivenessDisabled = true
    creator.tabs[1].css = 'd-none'
    creator.activeTab = tab;
    // creator.tabs[1].
    creator.tabs[4].css = 'tab-seperator'
    creator.tabs[5].css = 'tab-seperator'
    creator.JSON = { ...Content };
    creator.theme = { ...Theme };

    const handleActiveTabChange = (sender: any, options: any) => {
        const isDesignUnsaved = !desgSave.current.disabled;
        const isThemeUnsaved = !themeSave.current.disabled;
        const fromTab = sender.activeTab;
        const toTab = options.tabName;
        // Condition for design tab
        if (fromTab === 'designer' && toTab !== 'logic' && isDesignUnsaved) {
            options.allow = false;
            ConfirmTabSwitch(fromTab, toTab)
        }

        // Condition for theme tab
        if (fromTab === 'theme' && toTab !== 'logic' && isThemeUnsaved) {
            options.allow = false;
            ConfirmTabSwitch(fromTab, toTab)
        }

        // Condition for logic tab
        if (isDesignUnsaved && fromTab === 'logic' && toTab !== 'designer') {
            options.allow = false;
            ConfirmTabSwitch(fromTab, toTab)
        }
        if (creator.toolbarItems && !isDesignUnsaved && !isThemeUnsaved) {
            if (toTab === 'test') {
                const previewButton = creator.toolbarItems.find((item: any) => item.id === 'svd-preview');
                if (previewButton) {
                    previewButton.action = () => {
                        creator.activeTab = "designer";
                    }
                    previewButton.visible = true;
                    previewButton.iconName = 'icon-closecamera';
                    previewButton.title = '';
                    previewButton.css = '';
                    previewButton.iconSize = 22
                    previewButton.tooltip = 'Close Preview';
                }
            } else {
                const previewButton = creator.toolbarItems.find((item: any) => item.id === 'svd-preview');
                if (previewButton) {
                    previewButton.visible = true;
                    previewButton.title = 'Preview';
                    previewButton.iconName = '';
                    previewButton.css = 'btn-outline';
                    previewButton.iconSize = 24
                    previewButton.tooltip = 'Preview Form';
                    previewButton.action = () => creator.showPreview();
                }
            }
        }
    }

    function getAllQuestionNames() {
        let names: any = [];
        creator.survey.pages.forEach(page => {
            page.elements.forEach(element => {
                collectNames(element, names);
            });
        });
        return names;
    }

    function collectNames(element: any, names: any) {
        if (element.getType() === "panel") {
            element.elements.forEach((childElement: any) => collectNames(childElement, names));
        } else {
            names.push(element.name);
        }
    }

    const onQuestionAdded = (sender: any, options: any) => {
        options.question.valueName = generateUniqueValueName();
        if (options.question.title) {
            let item: any = creator.toolbox.getItemByName(options.question.getType());
            options.question.title = item.innerItem.title;
            let existingNames = getAllQuestionNames();
            let baseName = item.innerItem.title.replace(/\s+/g, '');
            let newName = baseName;
            let index = 1;
            if (existingNames.includes(newName)) {
                do {
                    newName = `${baseName}${index}`;
                    index++;
                } while (existingNames.includes(newName));
            }
            options.question.name = newName;

            if(!existingNames.includes(newName)) {
                if(options.question.jsonObj.type == 'monthfield') {
                    monthDefaultValue.forEach((monthName: any) => {
                        options.question.monthOptions.push(new ItemValue(monthName));
                    })
                }
                // if(options.question.jsonObj.type == 'fullname') {
                //     fullNameDefautlValue.forEach((fullName: any) => {
                //         options.question.prefixChoices.push(new ItemValue(fullName.value, fullName.text))
                //     })
                // }
            }
        }
        if (options.question.jsonObj?.type && ["privacypolicy", "termsconditions"].includes(options.question.jsonObj.type)) {
            options.question.isRequired = true;
        }

        if (options.question.jsonObj?.type && ['file'].includes(options.question.jsonObj.type)) {
            options.question.storeDataAsText = false;
        }
    }

    const onTestSurveyCreated = (sender: any, options: any) => {
        const survey = options.survey;
        survey.onUploadFiles.add((_: any, opt: any) => {
            const files = opt.files.map((file: any) => { return { file } });
            opt.callback(files);
        });
    }

    function getAllQuestionNamesForUpdate(valueName: any) {
        let names: any = [];
        creator.survey.pages.forEach(page => {
            page.elements.forEach((element: any) => {
                if(element.valueName !== valueName){
                    collectNames(element, names);
                }
            });
        });
        return names;
    }

    const debouncedUpdateName = debounce((target: any, newName: any) => {
        updatingProperty = true;
        setTimeout(() => {
          target.name = newName;
          updatingProperty = false;
        }, 0);
      }, 200);

    const onSurveyPropertyValueChanged = (sender: any, options: any) =>{
        if(updatingProperty) return;

        const getUniqueName = (name: string, suffix: number): any => {
            let uniqueName = suffix == 0 ? name.trim() : `${name.trim()}${suffix}`;
            let existingNames = getAllQuestionNamesForUpdate(options.target.valueName); 
            if(existingNames && existingNames.length && existingNames.includes(uniqueName)) {
                return getUniqueName(name, suffix+1);
            }
            else {
                return uniqueName;
            }                
        }
        if(options.name == 'title'){
            let newName = getUniqueName(options.target.title, 0).slice(0, 50);
            if (options.target.name !== newName) {
                newName = newName.replace(/\s+/g, '')
                debouncedUpdateName(options.target, newName);
            }
        }
    }

    useEffect(() => {
        creator.onTestSurveyCreated.add(onTestSurveyCreated)
        creator.onActiveTabChanging.add(handleActiveTabChange);
        creator.onQuestionAdded.add(onQuestionAdded)
        creator.onSurveyPropertyValueChanged.add(onSurveyPropertyValueChanged);
        desgSave.current = document.querySelector('[title="Save Form"]');
        themeSave.current = document.querySelector('[title="Save Theme"]');

        return () => {
            creator.onActiveTabChanging.remove(handleActiveTabChange)
            creator.onQuestionAdded.remove(onQuestionAdded)
            creator.onSurveyPropertyValueChanged.remove(onSurveyPropertyValueChanged);
            creator.onElementDeleting.remove(handleElementDelete)
            creator.onTestSurveyCreated.remove(onTestSurveyCreated)
        }
    }, [dispatch, creator])
    const runFetchFromOptimiserCode = ()=>{

        creator.onPropertyValueChanging.add(function (sender, options) {
            const { obj, propertyName, value, newValue }: any = options;
            if (propertyName == "ListShchema") {
                //console.log("pppppppppppppppppppppppppp")
                let choicesByUrl = new ChoicesRestful();
                choicesByUrl.url = `${domainUrl()}/api/getlistitems/` + newValue;   // Assuming newValue is the updated URL
                choicesByUrl.valueName = "Key";  // Update valueName property
                choicesByUrl.titleName = "Value";   // Update titleName property.
                choicesByUrl.path = "data";   // Update path property
                choicesByUrl.allowEmptyResponse = true;
                obj.setPropertyValue("choicesByUrl", choicesByUrl);
            }
    
        })
        Serializer.getProperty("dropdown", "choicesByUrl").visibleIf = function(obj){
            return !obj.Linkwithoptimiser;
          };
          Serializer.getProperty("tagbox", "choicesByUrl").visibleIf = function(obj){
            return !obj.Linkwithoptimiser;
          };
          Serializer.getProperty("dropdown", "choicesByUrl").visibleIndex = 2;
          Serializer.getProperty("tagbox", "choicesByUrl").visibleIndex = 2;
          Serializer.addProperty("dropdown", {
              visibleIndex:0,
              name: "Linkwithoptimiser",
              type:"boolean",
              category: "choicesByUrl",
              displayName:"Fetch From Optimiser CRM"
          });
          Serializer.addProperty("dropdown", {
              visibleIndex:1,
              name: "ListShchema",
              category: "choicesByUrl",
              displayName:"List Schema",
              dependsOn: [ "Linkwithoptimiser" ],
              visibleIf: (obj) => {
                return obj.Linkwithoptimiser == true;
              },
              choices: (obj:any, choicesCallback:any) => {
                const xhr = new XMLHttpRequest();
                xhr.open("GET", `${domainUrl()}/api/getlistschemalist`);
                xhr.setRequestHeader( "Content-Type", "application/x-www-form-urlencoded");
                xhr.onload = () => {
                  if (xhr.status === 200) {
                   
                    const response = JSON.parse(xhr.response);
                    const result = [];
                    // Make the property nullable
                    result.push({ value: null });
                    // Web service returns objects that are converted to the `{ value, text }` format
                    // If your web service returns an array of strings, pass this array to `choicesCallback`
                    if(response && response.status =="success" && response.data.length) {
                      response.data.forEach((item:any) => {
                        result.push({ value: item.Name, text: item.DisplayName });
                      });
                    }
                    choicesCallback(result);
                  }
                };
                xhr.send();
              }
          });
          Serializer.addProperty("tagbox", {
            visibleIndex:0,
            name: "Linkwithoptimiser",
            type:"boolean",
            category: "choicesByUrl",
            displayName:"Fetch From Optimiser CRM"
          });
          Serializer.addProperty("tagbox", {
            visibleIndex:1,
            name: "ListShchema",
            category: "choicesByUrl",
            displayName:"List Schema",
            dependsOn: [ "Linkwithoptimiser" ],
            visibleIf: (obj) => {
              return obj.Linkwithoptimiser == true;
            },
            choices: (obj:any, choicesCallback:any) => {
              const xhr = new XMLHttpRequest();
              xhr.open("GET", `${domainUrl()}/api/getlistschemalist`);
              xhr.setRequestHeader( "Content-Type", "application/x-www-form-urlencoded");
              xhr.onload = () => {
                if (xhr.status === 200) {
                  const response = JSON.parse(xhr.response);
                  const result = [];
                  // Make the property nullable
                  result.push({ value: null });
                  // Web service returns objects that are converted to the `{ value, text }` format
                  // If your web service returns an array of strings, pass this array to `choicesCallback`
                  if(response && response.status =="success" && response.data.length) {
                    response.data.forEach((item:any) => {
                      result.push({ value: item.Name, text: item.DisplayName });
                    });
                  }
                  choicesCallback(result);
                }
              };
              xhr.send();
            }
          });
    }
    if(projectConfig.OptimiserSyncEnabled)runFetchFromOptimiserCode();
    return (
        <SurveyCreatorComponent creator={creator} />
    )
}
