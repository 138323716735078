import { ComponentCollection, Serializer, SvgRegistry, type Question } from "survey-core";

SvgRegistry.registerIconFromSvg("icon-month", `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M96 32V64H48C21.5 64 0 85.5 0 112v48H448V112c0-26.5-21.5-48-48-48H352V32c0-17.7-14.3-32-32-32s-32 14.3-32 32V64H160V32c0-17.7-14.3-32-32-32S96 14.3 96 32zM448 192H0V464c0 26.5 21.5 48 48 48H400c26.5 0 48-21.5 48-48V192z"/></svg>`);

const monthNames = [
    "January", "February", "March",
    "April", "May", "June",
    "July", "August", "September",
    "October", "November", "December"
];

(<any>ComponentCollection).Instance.add({
    name: "monthfield",
    title: "Month",
    iconName: 'icon-month',
    questionJSON: {
        "type": "dropdown",
        "placeholder": "Select a month",
    },
    onInit() {
        const monthOptions = monthNames.map((m: any) => { return ({ value: m, text: m }) });
        Serializer.addProperty('monthfield', {
            name: "monthOptions",
            category: 'general',
            displayName: 'Month Options',
            type: "itemvalues",
            // default: monthOptions
        });
    },
    onLoaded(question: Question) {
        if (question) {
            question.title = question.title || 'Month';
            this.updateMonthOptions(question);
        }
    },
    updateMonthOptions(question: Question) {
        if (!!question.contentQuestion) {
            question.contentQuestion.choices = question.monthOptions;
        }
    },
    onItemValuePropertyChanged(question: Question, options: any) {
        if (options.propertyName === "monthOptions") {
            this.updateMonthOptions(question);
        }
    },
    onPropertyChanged(question: Question, propertyName: string, newValue: any) {
        if (propertyName === "monthOptions") {
            this.updateMonthOptions(question);
        }
    }
});
