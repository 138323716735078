import { useRef, useEffect } from 'react';
export default function TemplateEditor({ content, items2, TemplateType }: any) {
    const framRef: any = useRef(null);
    useEffect(() => {
        const onFrameLoaded = () => {
            framRef.current.contentWindow.postMessage({ type: "EmailContent", content, items2, TemplateType: TemplateType }, '*')
        }
        framRef.current.addEventListener('load', onFrameLoaded)
        return () => {
            framRef.current?.removeEventListener('load', onFrameLoaded)
        }
    }, [])


    return (
        <div className="editor-screen" >
            <iframe id='EmailFrame' ref={framRef} src={'/newsletter-editor/'}
                style={{ width: '100%', height: '100%', border: 'none', userSelect: 'none' }}></iframe>
        </div>
    )
}